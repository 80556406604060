import { onMounted, ref, Ref } from 'vue';
import { injectStrict } from '@/shared/utils/injectStrict';
import { ProductDetailsKey } from '../constants/injectables';
import { ProductDetails } from '@/shared/interfaces/productDetails';

interface IUseProductDetails {
  product: Ref<ProductDetails | undefined>;
  error: Ref<unknown>;
  isLoading: Ref<boolean>;
}

export const useProductDetails = (id: string): IUseProductDetails => {
  const service = injectStrict(ProductDetailsKey);
  const product = ref<ProductDetails>();
  const isLoading = ref<boolean>(false);
  const error = ref();

  onMounted(async () => {
    isLoading.value = true;
    try {
      const res = await service.getProductDetails(id);
      if (res.status === 200) {
        product.value = res.data;
      } else {
        error.value = res;
      }
    } catch (err) {
      error.value = err;
    } finally {
      isLoading.value = false;
    }
  });

  return {
    product,
    error,
    isLoading,
  };
};
