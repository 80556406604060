
import { defineComponent, PropType, watch, ref, computed } from 'vue';
import { DatePicker } from 'v-calendar';
import { i18n } from '@/root/i18n';
import { useTranslations } from '@/shared/composables/useTranslations';
import { DateRange, DateStringsRange } from '@/shared/interfaces/calendar';
import { useCalendarHighlights } from './composables/useCalendarHighlights';
import { useVersions } from '@/shared/composables/useVersions';
import {
  calculateDaysDifference,
  formatRangeValues,
  formatOpenEndedValues,
} from '@/shared/utils/formatDate';
import 'v-calendar/dist/style.css';
import './styles/calendar.css';

const emitValuesName = 'update:modelValue';

export default defineComponent({
  name: 'Calendar',
  components: {
    DatePicker,
  },
  props: {
    modelValue: {
      type: Object as PropType<DateRange>,
      required: true,
    },
    isFlexMode: {
      type: Boolean,
      default: false,
    },
    serviceRange: {
      type: Object as PropType<DateRange>,
      required: false,
    },
    returnRange: {
      type: Object as PropType<DateRange>,
      required: false,
    },
  },
  emits: [emitValuesName, 'daysChange'],
  setup(props, { emit }) {
    const { t } = useTranslations();
    const { isPl } = useVersions();
    const openEnded = ref(props.modelValue.end === t('calendar_open_ended'));
    const days = ref(0);
    const isTodaySelected = ref(true);
    const masks = { weekdays: 'WW', input: 'iso' };
    const options = {
      serviceRange: props.serviceRange,
      returnRange: props.returnRange,
    };
    const { attributes, getHighlightStyle, getOpenEndedHighlight } = useCalendarHighlights(
      t,
      options
    );
    const highlightStyle = computed(() => getHighlightStyle(days.value));
    const calendarTitle = computed(() =>
      openEnded.value
        ? `${t('calendar_open_ended')}`
        : `${days.value} ${t('calendar_days_selected')}`
    );

    const removeOpenEndedHighlight = () => {
      attributes.pop();
    };

    const addOpenEndedHightlight = (start: Date) => {
      attributes.push(getOpenEndedHighlight(start));
    };

    const updateOpenEndedHighlight = (start: Date) => {
      removeOpenEndedHighlight();
      addOpenEndedHightlight(start);
    };

    const updateIsTodaySelected = (start: string) => {
      isTodaySelected.value =
        new Date(start).toLocaleDateString() === new Date().toLocaleDateString();
    };

    const updateDate = (value: DateRange | Date) => {
      if (openEnded.value) {
        updateOpenEndedHighlight(value as Date);
        emit(emitValuesName, { start: value, end: t('calendar_open_ended') });
      } else {
        emit(emitValuesName, value);
      }
    };

    const updateDays = (range: DateStringsRange) => {
      if (range.end && !openEnded.value) days.value = calculateDaysDifference(range);
      else days.value = 0;
      if (range.end && range.start) emit('daysChange', range);
    };

    const getInputEvents = (events: unknown) => {
      if (openEnded.value) return { start: events, end: events };
      return events;
    };

    const getInputValues = (values: DateStringsRange | string) => {
      if (openEnded.value) {
        updateIsTodaySelected(values as string);
        return formatOpenEndedValues(values as string, t('calendar_open_ended'));
      }
      updateDays(values as DateStringsRange);
      updateIsTodaySelected((values as DateStringsRange).start);

      return formatRangeValues(values as DateStringsRange);
    };

    updateDate(props.modelValue);

    watch(
      () => openEnded.value,
      (newValue: boolean) => {
        if (newValue) {
          addOpenEndedHightlight(new Date());
          emit(emitValuesName, { start: new Date(), end: t('calendar_open_ended') });
        } else {
          removeOpenEndedHighlight();
          emit(emitValuesName, { start: new Date(), end: new Date() });
        }
      }
    );

    return {
      t,
      attributes,
      masks,
      highlightStyle,
      updateDate,
      openEnded,
      getInputValues,
      getInputEvents,
      days,
      calendarTitle,
      updateDays,
      isTodaySelected,
      i18n,
      isPl,
    };
  },
});
