import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0f90102a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "header-container",
  disabled: ""
}
const _hoisted_2 = ["onClickCapture"]
const _hoisted_3 = ["onClickCapture"]
const _hoisted_4 = { class: "absolute top-0 pl-2 lg:pl-3" }
const _hoisted_5 = { class: "selected-days" }
const _hoisted_6 = { class: "footer-container" }
const _hoisted_7 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_8 = { class: "ml-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseText = _resolveComponent("BaseText")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseSwitch = _resolveComponent("BaseSwitch")!
  const _component_DatePicker = _resolveComponent("DatePicker")!

  return (_openBlock(), _createBlock(_component_DatePicker, {
    modelValue: _ctx.modelValue,
    "onUpdate:modelValue": _ctx.updateDate,
    columns: _ctx.$screens({ default: 1, lg: 2, xl: 3 }),
    attributes: _ctx.attributes,
    "is-range": !_ctx.openEnded,
    titlePosition: "left",
    masks: _ctx.masks,
    "min-date": new Date(),
    "drag-attribute": _ctx.highlightStyle,
    "select-attribute": _ctx.highlightStyle,
    popover: { visibility: 'click' },
    locale: _ctx.i18n.global.locale.value,
    "is-required": "",
    class: _normalizeClass(["w-full", { 'today-selected': _ctx.isTodaySelected, 'pl-calendar': _ctx.isPl }]),
    color: "gray"
  }, {
    "header-title": _withCtx(({ monthLabel, year }) => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_BaseText, { class: "month-label" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(monthLabel), 1)
          ]),
          _: 2
        }, 1024),
        _createVNode(_component_BaseText, { class: "year-label" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(year), 1)
          ]),
          _: 2
        }, 1024)
      ])
    ]),
    "header-left-button": _withCtx(({ movePrevMonth }) => [
      _createElementVNode("button", {
        onClickCapture: movePrevMonth,
        class: "p-1 pr-1.5",
        type: "button"
      }, [
        _createVNode(_component_BaseIcon, {
          class: "arrow-icon",
          icon: "arrow"
        })
      ], 40, _hoisted_2)
    ]),
    "header-right-button": _withCtx(({ moveNextMonth }) => [
      _createElementVNode("button", {
        onClickCapture: moveNextMonth,
        class: "p-1 pl-1.5",
        type: "button"
      }, [
        _createVNode(_component_BaseIcon, {
          class: "arrow-icon rotate-180",
          icon: "arrow"
        })
      ], 40, _hoisted_3)
    ]),
    default: _withCtx(({ inputValue, inputEvents }) => [
      _renderSlot(_ctx.$slots, "default", {
        values: _ctx.getInputValues(inputValue),
        events: _ctx.getInputEvents(inputEvents)
      }, undefined, true)
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.calendarTitle), 1),
        _renderSlot(_ctx.$slots, "header", { isOpenEnded: _ctx.openEnded }, undefined, true)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", null, [
          (_ctx.isFlexMode)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createVNode(_component_BaseSwitch, {
                  modelValue: _ctx.openEnded,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.openEnded) = $event))
                }, null, 8, ["modelValue"]),
                _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.t('calendar_open_ended')), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        _renderSlot(_ctx.$slots, "footer", { isOpenEnded: _ctx.openEnded }, undefined, true)
      ])
    ]),
    _: 3
  }, 8, ["modelValue", "onUpdate:modelValue", "columns", "attributes", "is-range", "masks", "min-date", "drag-attribute", "select-attribute", "locale", "class"]))
}