import { resolveComponent as _resolveComponent, toHandlers as _toHandlers, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-676e422d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex w-full lg:mb-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_InputGroup = _resolveComponent("InputGroup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_InputGroup, {
      label: _ctx.t('product_request_date_from_label'),
      class: "w-1/2 mr-1.5 lg:mr-3"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_BaseInput, _mergeProps({
          class: "input",
          modelValue: _ctx.values.start
        }, _toHandlers(_ctx.events.start), { readonly: "" }), null, 16, ["modelValue"])
      ]),
      _: 1
    }, 8, ["label"]),
    _createVNode(_component_InputGroup, {
      label: _ctx.t('product_request_date_to_label'),
      class: "w-1/2"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_BaseInput, _mergeProps({
          class: "input",
          modelValue: _ctx.values.end
        }, _toHandlers(_ctx.events.end), { readonly: "" }), null, 16, ["modelValue"])
      ]),
      _: 1
    }, 8, ["label"])
  ]))
}