import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ebf3b634"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bg-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseHeading = _resolveComponent("BaseHeading")!
  const _component_BackButton = _resolveComponent("BackButton")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_BaseContainer = _resolveComponent("BaseContainer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BaseContainer, { class: "error-container" }, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createVNode(_component_BackButton, { class: "mt-5" }, {
            default: _withCtx(() => [
              _createVNode(_component_BaseHeading, {
                size: 4,
                class: "ml-2.5"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('base_back_button_text')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_BaseHeading, {
            size: 6,
            class: "error-text"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('error_page_message')), 1)
            ]),
            _: 1
          })
        ]),
        _createVNode(_component_BaseButton, {
          size: 2,
          variant: "primary",
          component: "router-link",
          class: "w-max mb-10",
          to: _ctx.routes.home.path
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('error_page_btn')) + " ", 1),
            _createVNode(_component_BaseIcon, {
              icon: "arrow",
              class: "w-3 ml-1 rotate-180"
            })
          ]),
          _: 1
        }, 8, ["to"])
      ]),
      _: 1
    })
  ]))
}