
import { defineComponent, PropType } from 'vue';
import { useTranslations } from '@/shared/composables/useTranslations';
import { DateRange } from '@/shared/interfaces/calendar';

interface InputEvents {
  start: unknown;
  end: unknown;
}

export default defineComponent({
  name: 'CalendarInputs',
  props: {
    values: { type: Object as PropType<DateRange>, required: true },
    events: { type: Object as PropType<InputEvents>, required: true },
  },
  setup() {
    const { t } = useTranslations();

    return { t };
  },
});
