import { useTranslations } from '@/shared/composables/useTranslations';
import { Option } from '@/shared/interfaces/options';

interface AvailableBranches {
  [key: string]: Option[];
}

interface IUseRequestOptions {
  deliveryOptions: Option[];
  availableBranches: AvailableBranches;
}

export const useDropdownsOptions = (): IUseRequestOptions => {
  const { t } = useTranslations();

  const deliveryOptions = [
    { value: 'pickup', label: t('product_request_radio_pickup') },
    { value: 'delivery', label: t('product_request_radio_delivery') },
  ];

  const availableBranchesDe = [
    {
      value: t('branch_einbeck_address'),
      label: t('branch_einbeck_label'),
    },
    {
      value: t('branch_gottingen_address'),
      label: t('branch_gottingen_label'),
    },
    {
      value: t('branch_wolfsburg_address'),
      label: t('branch_wolfsburg_label'),
    },
    {
      value: t('branch_braunschweig_address'),
      label: t('branch_braunschweig_label'),
    },
    {
      value: t('branch_hannover_address'),
      label: t('branch_hannover_label'),
    },
    {
      value: t('branch_giessen_address'),
      label: t('branch_giessen_label'),
    },
    {
      value: t('branch_hildesheim_address'),
      label: t('branch_hildesheim_label'),
    },
    {
      value: t('barnch_kassel_address'),
      label: t('barnch_kassel_label'),
    },
    {
      value: t('branch_magdeburg_address'),
      label: t('branch_magdeburg_label'),
    },
    {
      value: t('branch_nordhausen_address'),
      label: t('branch_nordhausen_label'),
    },
    {
      value: t('branch_sangerhausen_address'),
      label: t('branch_sangerhausen_label'),
    },
  ];

  const availableBranchesPl = [
    {
      value: t('branch_gdansk_address'),
      label: t('branch_gdansk_label'),
    },
    {
      value: t('branch_gdynia_address'),
      label: t('branch_gdynia_label'),
    },
    {
      value: t('branch_grudziadz_address'),
      label: t('branch_grudziadz_label'),
    },
    {
      value: t('branch_slupsk_address'),
      label: t('branch_slupsk_label'),
    },
  ];

  const availableBranchesLv = [
    {
      value: t('branch_riga_hanzas_address'),
      label: t('branch_riga_hanzas_label'),
    },
    {
      value: t('branch_riga_granita_address'),
      label: t('branch_riga_granita_label'),
    },
  ];

  const availableBranchesLt = [
    {
      value: t('branch_vilnius_address'),
      label: t('branch_vilnius_label'),
    },
    {
      value: t('branch_kaunas_address'),
      label: t('branch_kaunas_label'),
    },
    {
      value: t('branch_klaipeda_address'),
      label: t('branch_klaipeda_label'),
    },
  ];

  const availableBranches = {
    de: availableBranchesDe,
    lm: availableBranchesDe,
    pl: availableBranchesPl,
    lv: availableBranchesLv,
    lt: availableBranchesLt,
  };
  return { deliveryOptions, availableBranches };
};
