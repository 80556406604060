import { useVersions } from '@/shared/composables/useVersions';
import { ProductPricing } from '@/shared/interfaces/productDetails';
import { DateStringsRange } from '@/shared/interfaces/calendar';
import { calculateDaysDifference, calculateMonthsDifference } from '@/shared/utils/formatDate';

const { isPl, isDe } = useVersions();

const getPriceByDay = (days: number, pricing?: ProductPricing): number => {
  switch (true) {
    case days > 30:
      return pricing?.monthly || 0;
    case days > 7:
      return pricing?.weekly || 0;
    case days < 1:
      return 0;
    default:
      return pricing?.daily || 0;
  }
};

const getPriceOnlyMothly = (range: DateStringsRange, mothlyPrice: number): number => {
  const days = calculateDaysDifference(range);
  const months = calculateMonthsDifference(range) + 1;
  return (months * mothlyPrice) / days;
};

const getPricesDefault = (range: DateStringsRange, pricing?: ProductPricing): number => {
  const days = calculateDaysDifference(range);
  if (pricing?.daily) {
    return getPriceByDay(days, pricing);
  }
  if (pricing?.weekly) {
    const weeks = Math.ceil(calculateDaysDifference(range) / 7);
    return (weeks * 7 * pricing?.weekly) / days;
  }
  if (pricing?.monthly) {
    return getPriceOnlyMothly(range, pricing?.monthly) * 30;
  }
  return 0;
};

const getPricesPl = (range: DateStringsRange, pricing?: ProductPricing): number => {
  const days = calculateDaysDifference(range);
  if (pricing?.daily) {
    return pricing?.daily;
  }
  if (pricing?.weekly) {
    const weeks = Math.ceil(calculateDaysDifference(range) / 5);
    return (weeks * pricing?.weekly) / days;
  }
  if (pricing?.monthly) {
    const months = calculateMonthsDifference(range) + 1;
    return (months * pricing?.monthly) / days;
  }
  return 0;
};

export const getPrice = (range: DateStringsRange, pricing?: ProductPricing): number =>
  isPl ? getPricesPl(range, pricing) : getPricesDefault(range, pricing);

export const getPriceWithIncurance = (price: number, insurance = 0, quantity = 1): number =>
  isDe ? insurance * quantity + price : price;
