import { TranslationFun } from '@/shared/composables/useTranslations';
import { DateRange } from '@/shared/interfaces/calendar';
import { useVersions } from '@/shared/composables/useVersions';

const { isPl } = useVersions();

interface AttributesOptions {
  serviceRange?: DateRange;
  returnRange?: DateRange;
}

interface UseCalendarHighlights {
  attributes: unknown[];
  getOpenEndedHighlight: (start: Date) => unknown;
  getHighlightStyle: (days: number) => unknown;
}

export const useCalendarHighlights = (
  t: TranslationFun,
  options: AttributesOptions
): UseCalendarHighlights => {
  const attributes: unknown[] = [
    {
      highlight: {
        style: {
          borderColor: '#1a1a1a',
          width: '100%',
          height: '100%',
        },
        fillMode: 'outline',
      },
      dates: new Date(),
      popover: {
        label: t('calendar_highlights_today'),
        hideIndicator: true,
      },
      order: 3,
    },
    {
      highlight: {
        style: {
          background: '#ededed',
          borderColor: '#ededed',
        },
        fillMode: 'outline',
      },
      dates: { start: null, end: new Date(new Date().setDate(new Date().getDate() - 1)) },
      popover: {
        label: t('calendar_highlights_unavalable'),
        hideIndicator: true,
      },
      order: 3,
    },
  ];

  const optionalAttribute = {
    height: '10px',
    border: '2px solid white',
    flexGrow: 'unset',
    position: 'absolute',
  };
  const optionalAttributeBase = {
    borderRight: 'none',
    borderLeft: 'none',
    width: '100%',
  };
  const optionalAtrributeStart = {
    borderRight: 'none',
    borderTopLeftRadius: '9999px',
    borderBottomLeftRadius: '9999px',
    width: '50%',
    right: '0',
  };
  const optionalAttributeEnd = {
    borderLeft: 'none',
    borderTopRightRadius: '9999px',
    borderBottomRightRadius: '9999px',
    width: '50%',
    left: '0',
  };
  const serviceAttributeBase = {
    dates: options.serviceRange,
    popover: {
      label: t('calendar_highlights_service'),
      hideIndicator: true,
    },
    order: 0,
  };
  const returnAttributeBase = {
    dates: options.returnRange,
    popover: {
      label: t('calendar_highlights_return'),
      hideIndicator: true,
    },
    order: 1,
  };

  const plWeekendsAttribute = {
    dates: { weekdays: [1, 7] },
    popover: {
      label: t('calendar_highlights_unavalable'),
      hideIndicator: true,
    },
    order: 1,
  };

  const returnAttribute = (range: DateRange) =>
    (range.start as Date).getTime() === (range.end as Date).getTime()
      ? {
          ...returnAttributeBase,
          dot: {
            style: {
              background: '#B5B5B5',
              border: '2px solid white',
              width: '1rem',
              height: '1rem',
            },
          },
        }
      : {
          ...returnAttributeBase,
          bar: {
            start: {
              fillMode: 'light',
              style: {
                ...optionalAttribute,
                ...optionalAtrributeStart,
                background: '#B5B5B5',
                zIndex: '1',
              },
            },
            base: {
              fillMode: 'light',
              style: {
                ...optionalAttribute,
                ...optionalAttributeBase,
                background: '#B5B5B5',
                zIndex: '1',
              },
            },
            end: {
              fillMode: 'light',
              style: {
                ...optionalAttribute,
                ...optionalAttributeEnd,
                background: '#B5B5B5',
                zIndex: '1',
              },
            },
          },
        };

  const serviceAttribute = (range: DateRange) =>
    (range.start as Date).getTime() === (range.end as Date).getTime()
      ? {
          ...serviceAttributeBase,
          dot: {
            style: {
              background: '#FF640F',
              border: '2px solid white',
              width: '1rem',
              height: '1rem',
            },
          },
        }
      : {
          ...serviceAttributeBase,
          bar: {
            start: {
              fillMode: 'light',
              style: {
                ...optionalAttribute,
                ...optionalAtrributeStart,
                background: '#FF640F',
              },
            },
            base: {
              fillMode: 'light',
              style: {
                ...optionalAttribute,
                ...optionalAttributeBase,
                background: '#FF640F',
              },
            },
            end: {
              fillMode: 'light',
              style: {
                ...optionalAttribute,
                ...optionalAttributeEnd,
                background: '#FF640F',
              },
            },
          },
        };

  if (options.serviceRange) attributes.push(serviceAttribute(options.serviceRange));
  if (options.returnRange) attributes.push(returnAttribute(options.returnRange));
  if (isPl) attributes.push(plWeekendsAttribute);

  const getOpenEndedHighlight = (start: Date) => ({
    highlight: {
      style: {
        borderColor: '#1a1a1a',
      },
      fillMode: 'outline',
    },
    dates: { start, end: null },
    popover: {
      label: t('calendar_open_ended'),
      hideIndicator: true,
    },
    order: 2,
  });

  const getHighlightStyle = (days: number): unknown => ({
    highlight: {
      start: {
        fillMode: 'solid',
        style: {
          background: '#2B2B2B',
          borderColor: '#2B2B2B',
          zIndex: '1',
        },
      },
      base: {
        fillMode: 'solid',
        style: {
          background: '#505050',
          height: '100%',
        },
      },
      end: {
        fillMode: 'solid',
        style: {
          background: '#2B2B2B',
          borderColor: '#2B2B2B',
        },
      },
    },
    popover: {
      label: `${days} ${t('calendar_highlights_days')}`,
      hideIndicator: true,
    },
    order: 2,
  });

  return { attributes, getHighlightStyle, getOpenEndedHighlight };
};
