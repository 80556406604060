import { useI18n } from 'vue-i18n';

export type Currency = (value: number, type?: string) => string;

export interface UseNumbersType {
  c: Currency;
  cPerDay: Currency;
}

export const useNumbers = (): UseNumbersType => {
  const { n, t } = useI18n();

  const currency = (value: number, type = 'currency') => n(value, type).replace(',00', ',-');

  const currencyPerDay = (value: number, type = 'currency') =>
    `${n(value, type).replace(',00', ',-')}${t('base_per_day')}`;

  return {
    c: currency,
    cPerDay: currencyPerDay,
  };
};
